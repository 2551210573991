import React from 'react';
import './App.css';
import {Configuration, OpenAIApi} from 'openai'

const API_KEY = "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";
const API_KEY_2 = "sk-U2KOZb5Q-d5D8cJCW_M8Qdr28rquCesi3kNGDYl8OrT3BlbkFJz52oZoGIsywy71cn45A_Z610VZgEGw-CCvCdAjHtgA"

class App extends React.Component{
  constructor(props) {
    super(props)

    this.state = {
      prompt: '',
      promptres: '',
      promptInput: '',
      messages: [
                  { role: 'system', content: 'You are a helpful assistant. Please give response in html format' }
                ],
      isLoading: false
      }

    this.onTextChange = this.onTextChange.bind(this)
    this.doSubmit = this.doSubmit.bind(this)

    this.onInputChange = this.onInputChange.bind(this)
    this.clickSubmit = this.clickSubmit.bind(this)
    }

  onTextChange(eve){
    this.setState({prompt: eve.target.value})
    }
  async doSubmit(){
    // console.log(this.state.prompt)
    this.setState({promptres: 'Loading...'})

    const configuration = new Configuration({
      apiKey: API_KEY
      });

    const openai = new OpenAIApi(configuration);

    let apipath = 'https://api.openai.com/v1/completions'
    // let apipath = 'https://api.openai.com/v1/images'

    const APIBody = {
      "model": "text-davinci-003",
      "prompt": this.state.prompt,
      "temperature": 0,
      "max_tokens": 4000,
      "top_p": 1.0,
      "frequency_penalty": 0.0,
      "presence_penalty": 0.0
      }

    // const APIBody = {
    //   "prompt": this.state.prompt,
    //   }



    // await fetch(apipath, {
    //   mode: 'cors',
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Authorization": "Bearer " + API_KEY
    //     },
    //   body: JSON.stringify(APIBody)
    //   }).then((data) => {
    //     return data.json();
    //   }).then((data) => {
    //     console.log(data);
    //     // this.setState({promptres: data.choices[0].text})
      
    // });


    await openai.createImage({
      prompt: this.state.prompt,
      n: 10,
      size: "512x512",
      });


    }

  scrollContent(){
    let contentDiv = document.getElementById('content')
    contentDiv.scrollTop = contentDiv.scrollHeight - contentDiv.offsetHeight
    }
  onInputChange(eve){
    this.setState({promptInput: eve.target.value})
    }
  async clickSubmit(){
    let messages = [...this.state.messages]
    messages.push({ role: 'user', content: this.state.promptInput })
    this.setState({messages: messages, promptInput: '', isLoading: true})

    setTimeout(this.scrollContent, 100)

    let payload = {
        model: 'gpt-3.5-turbo',
        messages: messages,
      }

    await fetch('https://api.openai.com/v1/chat/completions', {
      mode: 'cors',
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + API_KEY_2
        },
      body: JSON.stringify(payload)
      }).then((data) => {
        // console.log(data);
        return data.json();
      }).then((data) => {
        // console.log(data.choices[0])
        // console.log(data.choices[0].message.content);
        messages.push({role: data.choices[0].message.role, content: data.choices[0].message.content})
        this.setState({messages: messages, isLoading: false})
        setTimeout(this.scrollContent, 100)
      
    });
    }
  onInputKeyPress(eve){
    var keycode = (eve.keyCode ? eve.keyCode : eve.which);
    if (keycode === 13) {
      this.clickSubmit()
      }
    else{
      
      }
    }

  componentDidMount() {
    
    }
  UNSAFE_componentWillReceiveProps(props: Object){
    
    }
  
  
  
  render() {
    
    return (
      <div className="chat-cont">
      
        <div className="header">
          
        </div>
        <div className="content" id="content" >
          {
            this.state.messages.map(function(item, index){
                          return <div key={index} className={item.role === 'user' ? "content-out-user" : "content-out-assist"} >
                                    {
                                      item.role !== 'system' && 

                                      <div className={item.role === 'user' ? "content-in content-in-user" : "content-in content-in-assist"} dangerouslySetInnerHTML={{__html:item.content}} >
                                        
                                      </div>
                                    }
                                  </div>
                          }.bind(this))
          }
          {
            this.state.isLoading === true && 

            <div className="loader">
              <svg width="30" height="10" viewBox="0 0 120 30" fill="#59c4f3"><circle cx="15" cy="15" r="15"><animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"></animate><animate attributeName="fillOpacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="60" cy="15" r="9" fillOpacity="0.3"><animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear" repeatCount="indefinite"></animate><animate attributeName="fillOpacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="105" cy="15" r="15"><animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"></animate><animate attributeName="fillOpacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"></animate></circle></svg>
            </div>
          }
            
        </div>

        <div className="user-input">
          <input type="text" onChange={(eve) => this.onInputChange(eve)} onKeyPress={(eve) => this.onInputKeyPress(eve)} value={this.state.promptInput} />
          <div className="search-button" onClick={(eve) => this.clickSubmit(eve)} >
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="26" width="26"><path d="M48 448l416-192L48 64v149.333L346 256 48 298.667z"></path></svg>
          </div>
        </div>
      </div>
      )
    }
  }

export default App;
